import * as yup from 'yup'
import { t } from '../../common/i18n'
import { ValidateCP } from '../../specific/helpers/validation/validationCP'
import { validatePhone } from '../../specific/helpers/validation/validationPhone'

export interface IMinContactFields {
  name: string
  email: string
  password: string
  confirmPassword: string
  checkboxPrivacy: boolean
  coment: string
}
export const minContactFields = {
  name: yup
    .string()
    .required(
      t('validations.register.required', { label: t('formLabels.name') })
    ),
  email: yup
    .string()
    .email(t('validations.register.format', { label: t('formLabels.email') }))
    .required(
      t('validations.register.required', { label: t('formLabels.email') })
    ),
  phone: yup
    .string()
    .test(
      'test-phone_number',
      '${message}',
      validatePhone
    )
    .required(
      t('validations.register.required', { label: t('formLabels.phone') })
    ),
  coment: yup
    .string()
    .required(
      t('validations.register.required', { label: t('formLabels.coment') })
    ),
  checkboxPrivacy: yup
    .boolean()
    .oneOf([true], t('validations.register.accept_terms')),
}

export interface IFullContactFields extends IMinContactFields {
  phone: string
  postCode: string
}
export const fullContactFields = {
  ...minContactFields,

  postCode: yup
    .string()
    .test(
      'test-codigopostal',
      t('validations.form_facturacion.postalCode.invalid'),
      cp => ValidateCP(cp)
    )
    .required(
      t('validations.register.required', { label: t('formLabels.post_code') })
    ),
}

export interface IpreContactFields extends IMinContactFields {
  phone: string
  postCode: string
}
export const preContactFields = {
  ...minContactFields,

  vehicle: yup
    .string()
    .required(
      t('validations.register.required', { label: t('formLabels.vehiculo') })
    ),
  service: yup
    .string()
    .required(
      t('validations.register.required', { label: t('formLabels.servicios') })
    ),
  matricula: yup
    .string()
    .required(
      t('validations.register.required', { label: t('formLabels.matricula') })
    ),
}
