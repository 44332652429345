import React from 'react'
import { Link, navigate } from 'gatsby'

import route from '../../../utils/route'
import styles from './index.module.scss'
import { FormattedMessage } from 'react-intl'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import Layout from '../../Layout'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import MainFormContainer from '../../layouts/mainFormContainer/MainFormContainer'
import { t } from '../../../../common/i18n'
import Done from '../../Done/Done'

export interface GraciasProps {
  path: string
  token: boolean
}

export default function Gracias({ path, token }: GraciasProps) {
  if (token === false) {
    navigate(route('index'))
  }
  const goToPreviousStep = () => {
    navigate(route('index'))
  }
  return (
    <Layout seoData={null} selector={true} onGoBack={goToPreviousStep}>
      <LayoutSelector hideSelectorSteps subTitle={t('contacto.title')}>
        <MainFormContainer>
          <div className={styles.services_content}>
            <div className={styles.icon_mini}>
              <Done />
            </div>

            <p className={styles.text_cita_reservada}>
              <FormattedMessage id="contacto.confirmacion.confirmacion" />
            </p>

            <div className={styles.button_container}>
              <Link className={styles.goToButton} to="/">
                {t('revisiones.volver_inicio')}
              </Link>
            </div>
          </div>
        </MainFormContainer>
        <FunnelSideBar />
      </LayoutSelector>
    </Layout>
  )
}
