import { Router } from '@reach/router'
import { navigate, graphql } from 'gatsby'
import React, { useState } from 'react'
import Contacto from '../../components/contacto/ContactPage/index'
import Gracias from '../../components/contacto/gracias/gracias'
import route from '../../utils/route'
import { SeoData } from '../../../../src/common/types/Seo'

interface Props {
  data: {
    seoData: SeoData
  }
}
export default function AsistenteContacto({ data }: Props) {
  const [token, settoken] = useState(false)

  const gotoContact = token => {
    if (token === true) {
      settoken(true)
      navigate(route('contacto.gracias'))
    }
  }
  return (
    <Router>
      <Contacto
        seoData={data.seoData}
        gotoContact={gotoContact}
        path={route('contacto.index')}
      />
      <Gracias token={token} path={route('contacto.gracias')} />
    </Router>
  )
}

export const pageQuery = graphql`
  query contactoPageQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
