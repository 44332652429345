import React from 'react'
import { FieldErrors, FieldName } from 'react-hook-form/dist/types'
import { FormattedMessage } from 'react-intl'
import DropDown, { Props } from '../dropDown/DropDown'
import Error from '../error/Error'
import FormError from '../FormError/FormError'
import styles from './index.module.scss'

interface WithFormError<T> {
  name: FieldName<T>
  errors: FieldErrors<T>
}
interface WithStringError {
  name: string
  errors: string
}
type withErrors<T> = WithFormError<T> | WithStringError

type InputCestaProps<T> = {
  labelId: string
  inputSize?: 'normal' | 'big' | 'small' | 'xl' | 'xxl'
  disabled?: boolean
  defaultSelected?: string | null
  labelAbove?: boolean
} & withErrors<T> &
  Props

function InputCestaDropDown<T>({
  labelId,
  errors,
  inputSize,
  name,
  disabled,
  defaultSelected,
  ...rest
}: InputCestaProps<T>) {
  return (
    <div className={styles.inputCesta_wrapper}>
      <label className={styles.label}>
        <FormattedMessage id={labelId} />
      </label>
      <div>
        <DropDown
          defaultSelected={defaultSelected}
          name={name}
          disabled={disabled}
          size={inputSize}
          {...rest}
        />
        {typeof errors === 'string' ? (
          errors && <Error message={errors}></Error>
        ) : (
          <FormError errors={errors} field={name as FieldName<T>} />
        )}
      </div>
    </div>
  )
}

export default InputCestaDropDown
