import React from 'react'
import Next from '../../../../assets/images/Talleres/next.svg'
import { intl, t } from '../../../../i18n'
import Button from '../../../buttons/Button'
import styles from './index.module.scss'

type CestaLastRowProps = {
  priceWithoutServices?: number
  priceWithServices?: number
  openSelectorModal?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => Promise<void>
  onComprarClick?: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  onBackClick?: (
    event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void
  onContinuarClick?: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  onSendClick?: (data: any) => void
  comprarMobile?: boolean
}

export default function CestaLastRow({
  priceWithoutServices,
  priceWithServices,
  openSelectorModal,
  onComprarClick,
  onBackClick,
  onSendClick,
  comprarMobile,
  onContinuarClick,
}: CestaLastRowProps) {
  if (!comprarMobile) {
    return (
      <div className={styles.last_row_container}>
        {openSelectorModal && (
          <a
            onClick={openSelectorModal}
            className={styles.last_row_container__more_neumaticos}
            href="#selector-modal">
            {t('cesta.general.añadir_neumaticos')}
          </a>
        )}
        {onBackClick && (
          <a
            onClick={onBackClick}
            className={styles.last_row_container__more_neumaticos}>
            {t('cesta.general.volver_atras')}{' '}
          </a>
        )}

        {onComprarClick ? (
          <Button buttonClass="next_button" onClick={onComprarClick}>
            {t('tu_solicitud.comprar')}
          </Button>
        ) : null}
        {onContinuarClick ? (
          <Button onClick={onContinuarClick} buttonClass="next_button">
            {t('tu_solicitud.continuar')}
            <Next />
          </Button>
        ) : null}
        {onSendClick ? (
          <Button onClick={onSendClick} buttonClass="next_button">
            {t('registerForm.enviar')}
            <Next />
          </Button>
        ) : null}
      </div>
    )
  }
  return (
    <div className={styles.comprar_mobile_container}>
      {onComprarClick ? (
        <Button onClick={onComprarClick}>{t('tu_solicitud.comprar')}</Button>
      ) : null}
      {onContinuarClick ? (
        <Button onClick={onContinuarClick}>
          {t('tu_solicitud.continuar')}
        </Button>
      ) : null}
      <div className={styles.total_price_mobile}>
        <span className={styles.total_text}>
          {t('resultados_neumaticos.total')}:{' '}
        </span>
        <span className={styles.total_price}>
          {intl.formatNumber(priceWithServices || priceWithoutServices, {
            style: 'currency',
            currency: 'EUR',
          })}
        </span>
      </div>
    </div>
  )
}
