import React, { useState } from 'react'
import Mantenimiento from '../../../assets/cita-previa/icon_mantenimiento.svg'
import Neumaticos from '../../../assets/cita-previa/icon_neumaticos.svg'
import Revisiones from '../../../assets/cita-previa/icon_preitv.svg'
import Mecanica from '../../../assets/cita-previa/icon_mecanica.svg'
import styles from './index.module.scss'
import { FormattedMessage } from 'react-intl'
import { t } from '../../../../common/i18n'
import Button from '../../buttons/Button'
import Next from '../../../assets/images/Talleres/next.svg'
import CestaLastRow from '../../cesta/cestaItem/cestaLastRow/CestaLastRow'

interface Props {
  goSelectedForm: (selected: string) => void
  onGoBack?: () => void
}

const icon = item => {
  switch (item) {
    case 'pedidos':
      return <Neumaticos />
    case 'atencion':
      return <Revisiones />
    case 'presupuesto':
      return <Mantenimiento />
    case 'taller':
      return <Mecanica />
    default:
      return null
  }
}

export default function MotivosContainer({ goSelectedForm, onGoBack }: Props) {
  const services = ['pedidos', 'atencion', 'presupuesto', 'taller']
  const [selected, setSelected] = useState('')

  const onSelectService = ({ service }) => {
    setSelected(service)
  }
  return (
    <div className={styles.services_content}>
      <React.Fragment>
        <span className={styles.title}>{t('contacto.servicios.motivo')}</span>
        <div className={styles.services_container}>
          {services.map((service, index) => {
            return (
              <div
                key={index}
                className={
                  selected === service ? styles.service_active : styles.service
                }
                onClick={() =>
                  onSelectService({
                    service: service,
                  })
                }>
                {icon(service)}
                {service === 'presupuesto' ? (
                  <p className={styles.service_name_presup}>
                    <FormattedMessage id={`contacto.servicios.${service}`} />
                    <FormattedMessage id={`contacto.servicios.${service}_2`} />
                  </p>
                ) : (
                  <p className={styles.service_name}>
                    <FormattedMessage id={`contacto.servicios.${service}`} />
                  </p>
                )}
              </div>
            )
          })}
        </div>
        <div className={styles.desktop}>
          <CestaLastRow
            onContinuarClick={() => goSelectedForm(selected)}
            onBackClick={onGoBack}></CestaLastRow>
        </div>
        <div className={styles.mobile}>
          <div className={styles.button_cont}>
            <Button
              buttonClass="next_button"
              onClick={() => goSelectedForm(selected)}>
              <FormattedMessage id="selector-desktop.continuar" />
              <Next />
            </Button>
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}
